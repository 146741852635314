import React from 'react'
import { IoDocumentTextOutline } from "react-icons/io5";

const About = () => {
  return (
    <div name="About" className="flex flex-col min-h-screen w-full relative mt-4 md:mt-1 pt-32 md:pt-16 mb-22 md:mb-32 items-center justify-center">
        <div className='w-full'>
            <h1 className='text-3xl md:text-5xl font-bold text-center'>
                About
                
            </h1>
            <div className="w-32 bg-black h-0.5 mx-auto mt-2"></div>
            
        </div>

       
      <div className="text-center max-w-screen-lg mx-auto flex flex-col items-center h-full px-8">
      <div>
            <p className='text-center font-light italic text-2xl md:text-2xl  mt-3 py-5'>
                "An Explorer, wanting to solve problems sitting behind a 1920 x 1080 pixels box"
            </p>
        </div>
         
          <div>
          <p className="text-lg md:text-xl text-gray-600 py-8 text-justify md:text-center">
            I'm a passionate software developer currently pursuing my Master's in Computer Science at USC. With extensive experience in full-stack development gained from multiple internships, I excel at creating robust and scalable software solutions. I bring a unique blend of technical expertise, design skills, and marketing knowledge, enabling me to deliver user-centric and visually appealing applications. I'm always eager to learn and collaborate, aiming to contribute innovative and high-quality solutions in every project I undertake.</p>
          </div>

          <div>
          <a href="https://drive.google.com/file/d/158FLe2V6wuiV1Jq6cJ2jiEOYlVgu062m/view?usp=sharing" target='_blank' rel="noreferrer">

            <button className="bg-gray-700 text-white px-6 py-3 rounded-lg  hover:scale-110 duration-190 cursor-pointer flex flex-row justify-between">
              Resume &nbsp; 
              <IoDocumentTextOutline size={20} color='white' />
            </button>
            </a>
          </div>


        </div>
        
    </div>
  )
}

export default About